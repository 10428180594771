import { Link, navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import Nav from '../components/nav/nav'
import '../css/insight-post.scss'
import PostOne from '../images/insight/post-one.png'
import PostTwo from '../images/insight/post-two.png'
import PostThree from '../images/insight/post-three.png'
import PostFour from '../images/insight/post-four.png'
import PostFive from '../images/insight/post-five.png'
import ArrowRight from '../images/icons/arrow-right.svg'
import { FaAngleLeft } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";

import Axios from 'axios';

const InidividualPost = ({ location }) => {

    const [selectedPost, setSelectedPost] = useState({})
    const [loader, setLoader] = useState(false)
    const [post, setPost] = useState([])

    const dateFormatter = (date) => {
        const unix = Date.parse(date)
        const convertedDate = new Date(unix)
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let year = convertedDate.getFullYear();
        let month = months[convertedDate.getMonth()];
        let day = convertedDate.getDate();

        return day + ' ' + month + ', ' + year
    }

    const navigateToInsight = () => {
        navigate('/insights-posts')
        localStorage.removeItem('strdPost')
    }

    const getRelatedPost = async (postId) => {
        setPost([])
        setLoader(true)
        try {
            const results = await Axios.get(`https://procentriciq.com/portal/api/related-post/${postId}`)

            if (results.status === 200) {
                setPost(results.data.response.post)
            }
        } catch (error) {
            console.error(error)
        }
        finally {
            setLoader(false)
        }
    }


    useEffect(() => {
        if (location.state !== null) {
            setSelectedPost(location.state.selectedPost ? location.state.selectedPost : '')

            localStorage.setItem('strdPost', JSON.stringify(location.state.selectedPost))
        } else {
            let storedSelectedPost = JSON.parse(localStorage.getItem('strdPost'))
            if (storedSelectedPost) {
                setSelectedPost(storedSelectedPost)
            } else {
                navigate('/insights-posts')
            }
            // setSelectedPost('')
        }
    }, [])

    useEffect(() => {
        if (selectedPost) {
            if (selectedPost.id && selectedPost.id !== '') {
                getRelatedPost(selectedPost.id)
            }
        }
    }, [selectedPost])


    return (
        <Layout>
            <Nav
                isWhite={true}
            />

            <div className="px-5 pt-4">
                <p onClick={() => navigateToInsight()} className="cursor black-link-text mb-0 my-auto"><span className="mr-1"><FaAngleLeft /></span>Insights</p>
            </div>

            <div className="digital-usecase pb-5 pt-4">
                {selectedPost &&
                    <div className="bg-individual-post" style={{ backgroundImage: `url(${selectedPost.image})` }}></div>
                }
            </div>


            <div className="container">
                <h1 className="font-weight-bold text-center mt-md-5 big-text">{selectedPost && selectedPost.title}</h1>

                <div className="row">
                    <div className="col-md-4"></div>


                    <div className="col-md-4">
                        <p className="text-center mt-4 mb-4 pb-2 text-uppercase inner-page-breadcrumb">
                            <span className="text-secondary mr-2">{selectedPost && selectedPost.category && selectedPost.category.name}</span>
                            {(selectedPost && selectedPost.user && selectedPost.user.first_name) + ' ' + (selectedPost && selectedPost.user && selectedPost.user.last_name)}
                        </p>
                    </div>

                    {selectedPost &&
                        <div className="col-md-4">
                            <p className="text-right mt-4 mb-4 pb-2 small">{selectedPost && dateFormatter(selectedPost.updated_at)}</p>
                        </div>
                    }
                </div>


                <div style={{ borderBottom: '5px solid #E41700' }}></div>
            </div>

            <div className="container">
                <div className="row mt-5 pt-3">
                    <div className="col-md-3">
                        {selectedPost && selectedPost.duration &&
                            <p>{selectedPost.duration} minutes read</p>
                        }
                    </div>
                    <div className="col-md-6">
                        <h4 className="font-weight-bold" style={{ fontSize: '1.5rem', lineHeight: '37px' }}>
                            {selectedPost && selectedPost.preview_text}
                        </h4>

                        <div className="my-4" style={{ borderBottom: '5px solid #E41700' }}></div>


                        {selectedPost &&
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: selectedPost.content
                                }}></div>
                        }


                        {/* <h5 className="font-weight-bold mt-5 pt-3 mb-3" style={{ fontSize: '1.5rem' }}>The Client</h5>

                        <p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary">
                            About 10 years ago, a parcel courier (one of those companies that brings cardboard boxes to your door) built an innovation unit to help power their future. Senior executives
                            had held inspiring conversations and decided that “innovation” would build the future of their company, so they built a new team and recruited a former startup guy to run it.
                            Everyone had high hopes for the team’s success.
                            </p>

                        <p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary">What happened next? The startup guy built them a startup. The team found some cool technology and a strong customer need.
                        They used that to build, launch, and sell a profitable new solution to a small but growing pool of customers. </p>

                        <p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary">Was it a success? Well, only to some stakeholders.
                        When the team began to share their results, they faced surprisingly hostile reception: One senior executive wanted to know when the innovation division was going to yield strategic insights for the core company.
                        Another asked when it would start developing new technologies for the core business. A third wondered when it would train her teams in innovation. Despite some wins in the market, few stakeholders were happy with the results.
                        Within 18 months of their launch, they were disbanded. </p>

                        <p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary">This company fell into a surprisingly common trap: Everyone thought that “innovation” was important, but nobody ever talked about why they wanted it.
                        It was like the execs had asked a waiter for ice cream and then got upset when their server showed up with Rum Raisin. So, how can you avoid the Outcome Trap? Work backwards. First, define why innovation is important and why now.
                        Once you define the outcomes needed from innovation, you can craft a portfolio of projects designed to meet those goals. That portfolio then suggests the talent, skills, and even the number of people or teams you’ll need to drive innovation.
                        When we break them down, successful innovation portfolios consist of a mix of four activities. In the graphic below, we’ve plotted businesses and teams based on our evaluation of their primary objective.
                            </p> */}
                    </div>
                    <div className="col-md-3"></div>
                </div>

            </div>

            <div className="py-5"></div>

            <div className="container mb-5 pb-5">
                {post && post.length > 0 &&
                    <h4 className="text-hr"><span>Related posts</span></h4>
                }

                {loader &&
                    <div className="text-center">
                        <ClipLoader

                        />
                        <h5 className="pt-1">Loading...</h5>
                    </div>
                }

                <div className="insight-posts row mt-5 pt-5">
                    {
                        post && post.length > 0 && post.map((item, index) => <div key={index} className="col-md-4 mb-md-0 mb-5 pb-md-0 pb-4">
                            <div className="post-bg">
                                <img src={item.image} alt="background" />
                            </div>

                            <div className="row">
                                <div className="col-7">
                                    <p className="text-uppercase mt-4 inner-page-breadcrumb"><span className="text-secondary mr-2">{item.category.name}</span>{item.user.first_name + ' ' + item.user.last_name}</p>
                                </div>

                                <div className="col-5 text-right">
                                    <p className="text-uppercase mt-4 inner-page-breadcrumb">{dateFormatter(item.created_at)}</p>
                                </div>
                            </div>


                            <Link to={`/individual-post`} state={{ selectedPost: item }}>
                                <h4 className="cursor black-link-text" style={{ lineHeight: '30px', fontWeight: '400' }}>{item.title}</h4>
                            </Link>

                            <Link to={`/individual-post`} state={{ selectedPost: item }}>
                                <p className=" cursor black-link-text mb-0 pt-4 my-auto">Read Article <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p>
                            </Link>
                        </div>
                        )
                    }
                    {/* <div className="col-md-4 mb-md-0 mb-5 pb-md-0 pb-4">
                        <div className="post-bg">
                            <img src={PostThree} alt="background" />
                        </div>

                        <div className="row">
                            <div className="col-7">
                                <p className="text-uppercase inner-page-breadcrumb mt-4"><span className="text-secondary mr-2">Digital</span>ofe ivharue</p>
                            </div>

                            <div className="col-5 text-right">
                                <p className="text-uppercase mt-4 small">September 1, 2020</p>
                            </div>
                        </div>

                        <h4 className="cursor black-link-text" style={{ lineHeight: '30px', fontWeight: '400' }}>Reinforcing network security for a global conglomerate</h4>

                        <p className="cursor black-link-text mb-0 pt-4 my-auto">Read Article <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p>
                    </div> */}


                </div>
            </div>
            <div className="my-5 py-5"></div>

        </Layout>
    )
}

export default InidividualPost
